function hexToRgb(hex){
	return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
		,(m, r, g, b) => '#' + r + r + g + g + b + b)
		.substring(1).match(/.{2}/g)
		.map(x => parseInt(x, 16))
}

function rgbToArray(rgbString) {
	var rgbaArray = rgbString.replace(/[^\d,]/g, '').split(',');
	return rgbaArray.map(function(value) {
		return parseInt(value);
	});
}

function isDarkColor(color) {
	return !isLightColor(color)
}

function isLightColor(color) {
	return  1 - (0.299 * color.red + 0.587 * color.green + 0.114 * color.blue) / 255 < 0.5	
}

function addOpacityToColor(color, decimalOpacity){
	if (!color) return 
	
	let rgba = []
	if (color.includes("rgb")){
		rgba = rgbToArray(color)
	} else if (color.includes("#")) {
		rgba = hexToRgb(color)
	}

	return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${decimalOpacity})`
}

function darkenColor(color, factor) {
	const darkFactor = factor; // Adjust this value to control the darkness level
	const darkenedRed = Math.floor(color.red * darkFactor);
	const darkenedGreen = Math.floor(color.green * darkFactor);
	const darkenedBlue = Math.floor(color.blue * darkFactor);
	return { red: darkenedRed, green: darkenedGreen, blue: darkenedBlue };
}

function lightenColor(color, factor) {
    // Increase each RGB component by the factor (0 to 1)
    let r = Math.min(255, color.red + (255 - color.red) * factor);
    let g = Math.min(255, color.green + (255 - color.green) * factor);
    let b = Math.min(255, color.blue + (255 - color.blue) * factor);
    
    // Return the new color object
    return { red: Math.round(r), green: Math.round(g), blue: Math.round(b) };
}

function rgbToHex(color) {
	const red = color.red.toString(16).padStart(2, '0');
	const green = color.green.toString(16).padStart(2, '0');
	const blue = color.blue.toString(16).padStart(2, '0');
	return `#${red}${green}${blue}`;
}

function enhanceColor(initColor) {
	if (initColor == "lavender" || !initColor)
		initColor = "#e6e6fa"

	const rgb = hexToRgb(initColor)
	const color = {
		red: rgb[0],
		green: rgb[1],
		blue: rgb[2],
	}

	if (isLightColor(color)) {
		return rgbToHex(darkenColor(color, 0.8))
	}
	if (isDarkColor(color)) {
		return rgbToHex(lightenColor(color, 0.2))
	}
	return ("black")
}

export {
	hexToRgb,
	rgbToHex,
	enhanceColor,
	rgbToArray, 
	addOpacityToColor, 
	isDarkColor, 
	isLightColor,
	darkenColor,
	lightenColor,
}